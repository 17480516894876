.main {
	width: 100%;
	display: flex;
	flex: 1;
	flex-direction: row;
	flex-wrap: wrap;
	overflow: hidden;
}
.snake-container {
	width: 100vw;
	height: 75vh;
	display: flex;
	align-items: center;
	justify-content: center;
}
.grid {
	width: 500px;
	height: 500px;
	margin: auto;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
}
.grid-item {
	outline: 1px solid grey;
	width: 50px;
	height: 50px;
}

.snake {
	outline: 1px solid blue;
	background-color: blue;

	width: 50px;
	height: 50px;
}

.food {
	outline: 1px solid yellow;
	background-color: yellow;
	width: 50px;
	height: 50px;
}
